














































































import { Component, Prop } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import Exhibitor from '@/models/graphql/Exhibitor';
import { AppSocialMediaIcons } from '@/utils/enums/AppSocialMediaIcons';
import SocialLinksComponent from '@/components/SocialLinksComponent.vue';
import WidgetHelper from '@/utils/helpers/widgets/WidgetHelper';
import { Location } from 'vue-router';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { Getter } from 'vuex-class';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import EntityType from '@/utils/enums/EntityType';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';

/* eslint-disable @typescript-eslint/camelcase,no-underscore-dangle */
@Component({
  components: { SocialLinksComponent },
})
export default class CompanyContactComponent extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Prop({
    required: false,
    default: null,
  })
  private data!: Exhibitor[];

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  private get exhibitor(): Exhibitor | undefined {
    if (this.data && this.data.length > 0) {
      return this.data[0];
    }
    return undefined;
  }

  private get website(): string {
    if (this.exhibitor && this.exhibitor.urls && this.exhibitor.urls.length > 0) {
      const site = this.exhibitor.urls.find((url) => url.url
          && url.type
          && String(url.type)
            .toLocaleLowerCase() === 'website'
          && url.name === 'website');
      if (site && site.url) {
        if (!site.url.match(/^[a-zA-Z]+:\/\//)) {
          return `http://${site.url}`;
        }
        return site.url;
      }
    }
    return '';
  }

  private get email(): string {
    if (this.exhibitor && this.exhibitor.urls && this.exhibitor.urls.length > 0) {
      const site = this.exhibitor.urls.find((url) => url.url && url.type && String(url.type)
        .toLocaleLowerCase() === 'email');
      if (site && site.url) {
        return site.url;
      }
    }
    return '';
  }

  private get phone(): string {
    if (this.exhibitor && this.exhibitor.urls && this.exhibitor.urls.length > 0) {
      const site = this.exhibitor.urls.find((url) => url.url && url.type && String(url.type)
        .toLocaleLowerCase() === 'phone');
      if (site && site.url) {
        return site.url;
      }
    }
    return '';
  }

  private get urls(): { url: string; icon: string }[] {
    let urls: { url: string; icon: string }[] = [];
    if (this.exhibitor && this.exhibitor.urls && this.exhibitor.urls.length > 0) {
      urls = this.exhibitor.urls
        .filter((url) => url.url && !['email', 'phone', 'secondary-website', 'website'].includes(url.type || ''))
        .map((url) => ({
          url: url && url.url && !url.url.match(/^[a-zA-Z]+:\/\//) ? `http://${url.url}` : url.url || '',
          icon: (AppSocialMediaIcons as Record<string, string>)[
            url.type ? url.type.toLowerCase() : 'website'
          ] || AppSocialMediaIcons.website,
        }));
    }
    return urls;
  }

  private get address(): string {
    if (this.exhibitor
        && this.exhibitor.addresses
        && this.exhibitor.addresses.length > 0) {
      const addr: string[] = [];
      let address = '';
      if (this.exhibitor.addresses[0].address1) {
        address = `${this.exhibitor.addresses[0].address1},\n`;
      }
      if (this.exhibitor.addresses[0].address2) {
        addr.push(this.exhibitor.addresses[0].address2);
      }
      if (this.exhibitor.addresses[0].city) {
        addr.push(this.exhibitor.addresses[0].city);
      }
      if (this.exhibitor.addresses[0].state) {
        addr.push(this.exhibitor.addresses[0].state);
      }
      if (this.exhibitor.addresses[0].country
          && this.exhibitor.addresses[0].country.name) {
        addr.push(this.exhibitor.addresses[0].country.name);
      }
      if (this.exhibitor.addresses[0].postalCode) {
        addr.push(this.exhibitor.addresses[0].postalCode);
      }
      return `${address}${addr.join(', ')}`;
    }
    return '';
  }

  private get canShowContact(): boolean {
    const salesServiceFeature = this.featureByKey(FeatureKeys.COMMUNITY_NEW_LIMITS_CALCULATION_FEATURE);
    const isNewLimitCalculationFeatureEnabled = salesServiceFeature && salesServiceFeature.enabled;
    if (isNewLimitCalculationFeatureEnabled && this.exhibitor) {
      const limit = this.exhibitor.slots
        .map((s) => s.quantity || 0)
        .reduce((a, b) => a + b);
      return limit !== 0;
    }
    return true;
  }

  created(): void {
    if (this.payload) {
      let uid = '';
      if (this.payload.companyCode) {
        uid = WidgetHelper
          .entityCode(this.payload.companyCode as string, this.$route as unknown as Location);
      }
      if (this.payload.entityCode) {
        uid = WidgetHelper
          .entityCode(this.payload.entityCode as string, this.$route as unknown as Location);
      }
      const filter = {};
      if (!this.payload.type || this.payload.type === 'exhibitor') {
        Object.assign(filter, { uid });
      } else {
        Object.assign(filter, { [`${this.payload.type}s`]: { uid } });
      }

      this.setDataConfig([{
        alias: this.storeName,
        operation: 'exhibitor',
        gqlDefinition: buildQueryDefinition({
          cacheable: true,
          filter: {
            type: GqlEntityFilterType.EXHIBITOR_FILTER,
            value: filter,
          },
        }),
        fragmentName: 'exhibitorContactFragment',
      }]);
    } else if (this.widget) {
      this.setDataConfig(
        undefined,
        true,
      );
    }
  }

  private logStats(url: string): void {
    if (this.exhibitor) {
      this.$logger.logMatomoStats(
        this.authUser,
          this.community.code as string,
          EntityType.EXHIBITOR,
          StatLoggerActions.VIEW,
          url,
          this.exhibitor.id ? this.exhibitor.id : -1,
          this.exhibitor.uid,
          StatLoggerCategories.SOCIAL_LINK,
          this.$i18n.locale,
      );
    }
  }
}
